define("teleseller/routes/list", ["exports", "teleseller/constants"], function (_exports, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance"); }

  function _iterableToArray(iter) { if (Symbol.iterator in Object(iter) || Object.prototype.toString.call(iter) === "[object Arguments]") return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = new Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } }

  var _default = Ember.Route.extend({
    config: Ember.inject.service(),
    fastboot: Ember.inject.service(),
    windAuth: Ember.inject.service(),
    windChannel: Ember.inject.service(),
    windMnpOperators: Ember.inject.service(),
    brand: Ember.computed.readOnly('windChannel.brand'),
    checkoutURL: Ember.computed.readOnly('config.checkoutURL'),
    currentPartner: '',
    currentVendor: '',
    model: function model(_ref) {
      var _this = this;

      var internalpartnerid = _ref.internalpartnerid,
          selectedOperator = _ref.selectedOperator;

      // Fastboot ignored
      if (Ember.get(this, 'fastboot.isFastBoot')) {
        return new Promise(function (res) {
          return res([]);
        });
      } // No partner, no offers.


      if (!internalpartnerid || internalpartnerid === _constants.default.NO_OPERATOR) {
        this.windAuth.logout();
        return this.replaceWith('login');
      }

      Ember.set(this, 'selectedOperator', selectedOperator);
      Ember.set(this, 'partner_internal_id', internalpartnerid);
      return new Promise(function (res, rej) {
        _this.windAuth.checkLogin().then(function (auth) {
          if (auth.internalPartnerId !== internalpartnerid) {
            _this.windAuth.logout();

            return _this.replaceWith('login');
          }

          _this.getTelesellerOffers(internalpartnerid, auth).then(res).catch(rej);
        }).catch(rej);
      });
    },
    getTelesellerOffers: function getTelesellerOffers(internalPartnerId, authInfo) {
      var _this2 = this;

      var self = this;
      return new Promise(function (res) {
        _this2.store.query('ecommerce-partner', {
          filter: {
            'partner-id': internalPartnerId
          }
        }).then(function (result) {
          if (!result || !result.firstObject) {
            _this2.windAuth.logout();

            return _this2.replaceWith('login');
          }

          Ember.set(_this2, 'partner_internal_id', authInfo.internalPartnerId);
          Ember.set(_this2, 'partner_company_name', authInfo.companyName);
          self.setPartnerTitle(authInfo.companyName);
          self.store.query('ecommerce-product', {
            include: 'brand,additional-info,campaigns,detailed-info,partner,partner-list,pluses,pluses-online,mnp-operators',
            filter: {
              'partner-list.partner-id': authInfo.internalPartnerId
            }
          }).then(function (result) {
            return result.filter(function (r) {
              return r.isValid;
            });
          }).then(self._handleResult.bind(self)).then(res);
        });
      });
    },
    setPartnerTitle: function setPartnerTitle(partner) {
      if (Ember.get(this, 'fastboot.isFastBoot')) {
        return;
      }

      this.controllerFor('application').setHeaderTitle(partner);
    },
    _handleResult: function _handleResult(result) {
      if (result.length) {
        return result;
      } else {
        return []; // throw { code: CONSTANTS.ERRORS.NO_OFFERS };
      }
    },
    getAllMnpOperators: function getAllMnpOperators(offers) {
      var allLists = offers && offers.getEach('mnpList').filter(function (r) {
        return !!r;
      }) || [];

      var onlyUnique = function onlyUnique(value, index, self) {
        return self.indexOf(value) === index;
      };

      var list = [];
      allLists.forEach(function (l) {
        list = [].concat(_toConsumableArray(list), _toConsumableArray(l));
      });
      return list.filter(onlyUnique);
    },
    setupController: function setupController(controller, model) {
      var _this3 = this;

      this._super.apply(this, arguments);

      var offers = model; // (model.telesellerProducts && model.telesellerProducts.toArray() || []).map(p => p.offer);

      Ember.set(controller, 'offers', offers);
      var filteredOffers = offers;

      if (this.selectedOperator) {
        filteredOffers = filteredOffers.filter(function (r) {
          return r.get('mnpList').indexOf(_this3.selectedOperator) > -1;
        });
      }

      Ember.set(controller, 'doneLoading', true);
      Ember.set(controller, 'filteredOffers', filteredOffers);
      Ember.set(controller, 'partner_internal_id', this.partner_internal_id);

      if (!Ember.get(this, 'fastboot.isFastBoot')) {
        window.localStorage.setItem(_constants.default.STORACE_TLS_INTERNAL_PARTNER_ID, this.partner_id);
      }

      var usedMnpOperators = this.getAllMnpOperators(offers);
      this.windMnpOperators.getAll(usedMnpOperators).then(function (operators) {
        Ember.set(controller, 'mnpOperators', operators);
        Ember.set(controller, 'partner_company_name', _this3.partner_company_name);
      }).catch(function () {
        Ember.set(controller, 'mnpOperators', null);
      });
    },
    actions: {
      refresh: function refresh() {
        this.refresh();
      }
    }
  });

  _exports.default = _default;
});