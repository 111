define("teleseller/services/wind-mnp-operators", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var operatorList = [['idTIM', 'TIM'], ['idVODAFONE', 'Vodafone'], ['idILIADITALIA', 'ILIAD ITALIA'], ['idFASTWEBFULL', 'Fastweb Full'], ['idFASTWEBMOBILE', 'Fastweb Mobile'], ['idHOMOBILE', 'ho. Mobile'], ['idWINDTRE', 'Very Mobile'], ['idBTENIAMOBILE', 'BT Enia Mobile'], ['idBTITALIAFULLMVNO', 'BT Italia full MVNO'], ['idCMLINK', 'CM Link'], ['idCONADINSIM', 'Conad Insim'], ['idCOOPVOCE', 'COOP Voce'], ['idCOOPITALIAVOCE', 'COOP Italia Voce'], ['idDAILYTELECOM', 'Daily Telecom'], ['idDIGIMOBIL', 'DIGI MOBIL'], ['idDIGITEL', 'DIGITEL'], ['idENEGAN', 'ENEGAN'], ['idERGMOBILE', 'ERG Mobile'], ['idGREENICN', 'GreenICN'], ['idHG', 'H3G'], ['idINTERMATICA', 'Intermatica'], ['idKENAMOBILE', 'Kena Mobile'], ['idLINKEM', 'LINKEM'], ['idLYCAMOBILE', 'LYCA MOBILE'], ['idMUNDIO', 'MUNDIO'], ['idNEXTUS', 'NEXTUS'], ['idNOITEL', 'NOITEL'], ['idNOVA', 'Nova'], ['idNTMOBILE', 'NT mobile'], ['idNVMOBILE', 'NV mobile'], ['idOPTIMA', 'Optima'], ['idOVUNQUE', 'Ovunque'], ['idPLDTITALIASMARTPINOY', 'PLDT Italia Smart Pinoy'], ['idPLINTRON', 'PLINTRON'], ['idPOSTEMOBILE', 'PosteMobile'], ['idPOSTEMOBILEFULL', 'PosteMobile Full'], ['idRABONA', 'RABONA'], ['idRINGOMOBILE', 'Ringo Mobile'], ['idSPUSU', 'Spusu'], ['idTISCALI', 'Tiscali'], ['idUNOMOBILE', 'Uno Mobile'], ['idWELCOMEITALIA', 'Welcome Italia'], ['idWITHU', 'WithU mobile']];

  var _default = Ember.Service.extend({
    config: Ember.inject.service(),
    host: Ember.computed.readOnly('config.apiHost'),
    apiNamespace: 'steppenwolf/api/v1/ecommerce',
    getAll: function getAll() {
      var usedOperators = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
      return new Promise(function (res)
      /*rej*/
      {
        var operatorMap = {};
        operatorList.map(function (o) {
          return {
            code: o[0],
            name: o[1]
          };
        }).forEach(function (r) {
          operatorMap[r.code] = r.name;
        });
        var usedOps = [];

        for (var oper in operatorMap) {
          if (usedOperators.indexOf(oper) > -1) {
            usedOps.push({
              code: oper,
              name: operatorMap[oper]
            });
          }
        }

        res(usedOps.sort(function (a, b) {
          if (a.name > b.name) {
            return 1;
          } else if (a.name < b.name) {
            return -1;
          }

          return 0;
        }));
        /*
        if (!window.fetch) { return res([]); }
        fetch(`${this.host}/${this.apiNamespace}/ecommerce-mnp-operator/?page[size]=50`, {
          method: 'GET',
          cache: 'no-cache',
          headers: {
              'Content-Type': 'application/json',
          }
        })
        .then(resp => resp.json())
        .then(result => {
          const operators = result.data.map(r => {
            return {
              id: r.id,
              code: r.attributes.code,
              name: r.attributes.name
            }
          });
          const operatorMap = {};
          operators.forEach(r => {
            operatorMap[r.code] = r.name;
          });
          const usedOps = [];
          for (let oper in operatorMap) {
            if (usedOperators.indexOf(oper) > -1) {
              usedOps.push({ code: oper, name: operatorMap[oper] });
            }
          }
          res(usedOps);
        })
        .catch(rej);*/
      });
    }
  });

  _exports.default = _default;
});