define("teleseller/components/widi-radio", ["exports", "@dwind/widi-uikit/components/widi-radio"], function (_exports, _widiRadio) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _widiRadio.default;
    }
  });
});