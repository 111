define("teleseller/routes/landing", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    fastboot: Ember.inject.service(),
    beforeModel: function beforeModel() {
      /*this.replaceWith('list',
        get(this, 'fastboot.isFastBoot') ? CONSTANTS.NO_OPERATOR :
          window.localStorage.getItem(CONSTANTS.STORACE_TLS_INTERNAL_PARTNER_ID) || CONSTANTS.NO_OPERATOR
      );*/
      this.replaceWith('login');
    }
  });

  _exports.default = _default;
});