define("teleseller/models/ecommerce-campaign", ["exports", "ember-data/model", "ember-data/attr", "ember-data-model-fragments/attributes"], function (_exports, _model, _attr, _attributes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    banner: (0, _attr.default)('string'),
    code: (0, _attr.default)('string'),
    dailyLimit: (0, _attr.default)('string'),
    description: (0, _attr.default)('string'),
    limit: (0, _attr.default)('string'),
    logo: (0, _attr.default)('string'),
    name: (0, _attr.default)('string'),
    optionalBanner: (0, _attr.default)('string'),
    updated: (0, _attr.default)('string'),
    value: (0, _attr.default)('string'),
    valueCurrency: (0, _attr.default)('string'),
    validity: (0, _attributes.fragment)('validity'),
    isValid: Ember.computed('validity', {
      get: function get() {
        var _ref = Ember.get(this, 'validity') || {},
            lower = _ref.lower,
            upper = _ref.upper;

        var now = new Date();

        if (lower && lower >= now) {
          return false;
        }

        if (upper && upper <= now) {
          return false;
        }

        return true;
      }
    })
  });

  _exports.default = _default;
});