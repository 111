define("teleseller/controllers/list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    queryParams: ['selectedOperator'],
    selectedOperator: null,
    actions: {
      selectOperator: function selectOperator(value) {
        Ember.set(this, 'selectedOperator', !Ember.isEmpty(value) ? value : null);
        this.send('refresh');
      }
    }
  });

  _exports.default = _default;
});