define("teleseller/routes/login", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    config: Ember.inject.service(),
    onebrandPartnersURL: Ember.computed.readOnly('config.onebrandPartnersURL'),
    afterModel: function afterModel() {
      window.location.href = this.onebrandPartnersURL;
      this.controllerFor('application').setHeaderTitle('');
    }
  });

  _exports.default = _default;
});