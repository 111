define("teleseller/translations/it-it", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "widi-uikit": {
      "advantages": {
        "wind_why_convenient": "Perché ti conviene attivare le offerte online"
      },
      "card": {
        "discover": "Scopri"
      },
      "errors": {
        "validation": {
          "invalid": "Campo non valido",
          "required": "Campo obbligatorio"
        }
      },
      "features": {
        "wind_advantages": "I vantaggi di scegliere Wind"
      },
      "submit": {
        "continue": "Continua"
      },
      "tabs": {
        "toggle": {
          "show": "Mostra"
        }
      }
    },
    "wind": {
      "company": "WindTre S.p.A.",
      "details": {
        "additional_info": {
          "title": "Informazioni utili"
        },
        "always_connected": {
          "description": "Terminati i tuoi GIGA <strong>continui a navigare</strong> a 128 kb/s",
          "title": "Sempre connesso"
        },
        "auto_recharge": {
          "description": "Non rimani mai senza credito",
          "title": "Autoricarica"
        },
        "counter_cta": "Affrettati mancano solo:",
        "cta": {
          "back": "Passa a WindTre",
          "buy": "Acquista"
        },
        "each_month": "al mese",
        "easy_europe": {
          "description": "La tua offerta è <strong>valida anche in Europa</strong>!",
          "title": "Easy Europe"
        },
        "extra_costs": {
          "description": "SMS WINDTRE incluso<br/>Segreteria telefonica inclusa<br/>Hotspot incluso",
          "title": "No extracosti"
        },
        "gb": "Giga",
        "gb_unlimited": "Giga illimitati",
        "gigabytes": "Giga",
        "hotspot_included": {
          "description": "Utilizza il tuo smartphone come punto di&nbsp;<strong>accesso wi-fi</strong>",
          "title": "Hotspot incluso"
        },
        "lost_calls": {
          "description": "Servizio&nbsp;<strong>SMS WINDTRE</strong> incluso per sapere chi ti ha cercato",
          "title": "Mai chiamate perse"
        },
        "messages": "SMS",
        "messages_unlimited": "SMS illimitati",
        "minutes": "Minuti",
        "minutes_unlimited": "Minuti illimitati",
        "no_offers": "Nessuna offerta disponibile.",
        "offers": "Tutte le offerte",
        "online_only": "Solo online",
        "per_month": "al mese",
        "pick_category": "Scegli una categoria",
        "sms": "SMS",
        "under_control": {
          "description": "Con l'<strong>App WINDTRE</strong> gestisci la tua SIM ovunque sei",
          "title": "App WINDTRE"
        },
        "unlimited": "illimitati",
        "wind_advantages": "I vantaggi di scegliere WindTre",
        "wind_why_convenient": "Perché ti conviene attivare le offerte online"
      },
      "error": {
        "NOT_FOUND": {
          "message": "Operatore non esistente"
        },
        "NOT_VALID": {
          "message": "Operatore non valido"
        },
        "NO_OFFERS": {
          "message": "Nessuna offerta disponibile"
        },
        "check_latest_offer": "Scopri la nuova offerta",
        "check_other_offers": "Scopri le altre offerte",
        "check_other_offers_slogan": "Non perdere le altre offerte per te",
        "default": {
          "message": "Errore generico"
        },
        "generic": "Errore generico",
        "generic_title": "Oops!",
        "new_mobile_offer_first_line": "Scopri subito la nuova offerta <strong>All Digital</strong>",
        "new_mobile_offer_second_line": "o cerca quella che preferisci su",
        "offer_not_available": "L'offerta non è più disponibile",
        "offer_not_available_number": "Siamo spiacenti, l'offerta non è più disponibile per il numero inserito",
        "page_does_not_exist": "La pagina che stai cercando non esiste",
        "website_wind": "windtre.it"
      },
      "login": {
        "USER_NOT_ENABLED": "Utente non abilitato",
        "USER_NOT_EXIST": "Nome utente non valido",
        "USER_WRONG_LOGIN": "Dati di accesso errati",
        "access": "Accedi",
        "loginerror": "Username o password non validi",
        "password": "Password",
        "submit": "Entra",
        "subtitle": "Inserisci nome utente e password per accedere",
        "title": "Benvenuto nella vetrina delle offerte WindTre",
        "username": "Username"
      },
      "offer": {
        "after": "dopo",
        "each_month": "al mese",
        "euro_month": "€/mese",
        "first_months": "per i primi",
        "free": "Gratuita",
        "gigabytes": "Giga",
        "minutes": "Minuti",
        "months": "mesi",
        "no_offers": "Nessuna offerta disponibile.",
        "offers": "Offerte",
        "pick_category": "Scegli una categoria",
        "sms": "SMS",
        "then": "poi",
        "unlimited": "illimitati"
      },
      "privacy": "Privacy",
      "teleseller": {
        "all_operators": "Filtra per operatore",
        "loading": "Caricamento",
        "no_offers": "Nessuna offerta disponibile."
      },
      "vat": "Partita IVA: 13378520152"
    }
  };
  _exports.default = _default;
});