define("teleseller/models/ecommerce-teleseller-product", ["exports", "ember-data/model", "ember-data/relationships"], function (_exports, _model, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    offer: (0, _relationships.belongsTo)('ecommerce-product'),
    productId: Ember.computed.readOnly('offer.productId')
  });

  _exports.default = _default;
});