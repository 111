define("teleseller/router", ["exports", "teleseller/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Router = Ember.Router.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL
  });
  Router.map(function () {
    this.route('login', {
      path: '/login'
    });
    this.route('status', {
      path: '/_status'
    });
    this.route('list', {
      path: '/list/:internalpartnerid'
    });
    this.route('landing', {
      path: '/'
    });
    this.route('not-found', {
      path: '/*path'
    });
    this.route('generic-error', {
      path: '/error'
    });
  });
  var _default = Router;
  _exports.default = _default;
});