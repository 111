define("teleseller/services/wind-auth", ["exports", "teleseller/constants"], function (_exports, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(source, true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(source).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var _default = Ember.Service.extend({
    config: Ember.inject.service(),
    checkLogin: function checkLogin() {
      var _this = this;

      return new Promise(function (res, rej) {
        var cookie = _this.checkCookie();

        if (cookie) {
          return _this.validateCookie(cookie).then(res).catch(rej);
        } else {
          return res({
            auth: false,
            token: null
          });
        }
      });
    },
    checkCookie: function checkCookie() {
      var authCookie = window.localStorage && window.localStorage.getItem(_constants.default.STORAGE_TLS_AUTH) || null;

      if (authCookie) {
        try {
          authCookie = JSON.parse(atob(authCookie));
        } catch (ex) {
          authCookie = null;
        }
      }

      return authCookie || null;
    },
    validateCookie: function validateCookie(cookie) {
      return new Promise(function (res, rej) {
        if (cookie) {
          // Check token validity
          if (cookie.tokenExpires < new Date().getTime()) {
            rej();
          } else {
            res(cookie);
          }
        } else {
          rej();
        }
      });
    },
    logout2: function logout2() {
      if (window && window.localStorage) {
        localStorage.removeItem(_constants.default.STORAGE_TLS_AUTH);
        localStorage.removeItem(_constants.default.STORACE_TLS_INTERNAL_PARTNER_ID);
      }
    },
    login2: function login2(store, username, psw) {
      this.logout2();
      return new Promise(function (res, rej) {
        var fixedUsername = username.toLowerCase();
        store.query('ecommerce-partner', {
          filter: {
            'username-portal': fixedUsername
          }
        }).then(function (result) {
          // Case 1: User does not exist
          if (!result.firstObject) {
            return rej({
              error: true,
              code: 'USER_NOT_EXIST'
            });
          }

          var partnerInfo = {
            username: result.firstObject.get('usernamePortal'),
            password: result.firstObject.get('passwordPortal'),
            isEnabled: result.firstObject.get('isEnabledPortal'),
            companyName: result.firstObject.get('name'),
            internalPartnerId: result.firstObject.get('partnerId'),
            externalPartnerId: result.firstObject.get('vendor')
          }; // Case 2: Password is not valid

          if (!partnerInfo.password || window.atob(partnerInfo.password) !== psw) {
            return rej({
              error: true,
              code: 'USER_WRONG_LOGIN'
            });
          } // Case 3: User is not enabled


          if (!partnerInfo.isEnabled) {
            return rej({
              error: true,
              code: 'USER_NOT_ENABLED'
            });
          } // Everything is ok. Create new token.


          var EXPIRING_TIME = 1000 * 60 * 60 * 24; // one day

          var authInfo = _objectSpread({
            tokenExpires: new Date().getTime() + EXPIRING_TIME
          }, partnerInfo);

          delete authInfo.password;
          delete authInfo.isEnabled;

          if (window && window.localStorage) {
            localStorage.setItem(_constants.default.STORAGE_TLS_AUTH, btoa(JSON.stringify(authInfo)));
          }

          res(authInfo);
        });
      });
    }
  });

  _exports.default = _default;
});