define("teleseller/models/ecommerce-teleseller", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships"], function (_exports, _model, _attr, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    telesellerId: (0, _attr.default)('string'),
    name: (0, _attr.default)('string'),
    partner: (0, _relationships.belongsTo)('ecommerce-partner'),
    telesellerProducts: (0, _relationships.hasMany)('ecommerce-teleseller-product')
  });

  _exports.default = _default;
});