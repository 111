define("teleseller/routes/error", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    setupController: function setupController(controller) {
      var error = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

      if (!error.code) {
        error.code = 'default';
      }

      Ember.set(controller, 'error', error);

      this._super.apply(this, arguments);
    }
  });

  _exports.default = _default;
});