define("teleseller/models/ecommerce-detailed-info", ["exports", "ember-data/model", "ember-data/attr"], function (_exports, _model, _attr) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    title: (0, _attr.default)('string'),
    description: (0, _attr.default)('string')
  });

  _exports.default = _default;
});