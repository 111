define("teleseller/models/ecommerce-partner", ["exports", "ember-data/model", "ember-data/attr"], function (_exports, _model, _attr) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    partnerId: (0, _attr.default)('string'),
    name: (0, _attr.default)('string'),
    vendor: (0, _attr.default)('string'),
    usernamePortal: (0, _attr.default)('string'),
    passwordPortal: (0, _attr.default)('string'),
    isEnabledPortal: (0, _attr.default)('boolean')
  });

  _exports.default = _default;
});