define("teleseller/routes/authenticated-route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    windAuth: Ember.inject.service(),
    beforeModel: function beforeModel() {
      var _this = this;

      Ember.set(this, 'isCheckingLogin', true);
      this.windAuth.checkLogin().then(function (auth) {
        Ember.set(_this, 'loggedUser', auth);
        Ember.set(_this, 'isCheckingLogin', false);
      }).catch(function () {
        Ember.set(_this, 'isCheckingLogin', false);

        _this.transitionTo('login');
      });

      this._super.apply(this, arguments);
    }
  });

  _exports.default = _default;
});