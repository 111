define("teleseller/models/ecommerce-product", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships", "ember-data-model-fragments/attributes", "ember-inflector"], function (_exports, _model, _attr, _relationships, _attributes, _emberInflector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  _emberInflector.default.inflector.irregular('ecommerce-plus', 'ecommerce-plus');

  var _default = _model.default.extend({
    activationPrice: (0, _attr.default)('string'),
    activationPriceCurrency: (0, _attr.default)('string'),
    additionalInfo: (0, _relationships.hasMany)('ecommerce-additional-info'),
    bannerDesktop: (0, _attr.default)('string'),
    bannerMobile: (0, _attr.default)('string'),
    campaigns: (0, _relationships.hasMany)('ecommerce-campaign'),
    brand: (0, _relationships.belongsTo)('ecommerce-brand'),
    deliveryTypes: (0, _attr.default)(''),
    detailedInfo: (0, _relationships.hasMany)('ecommerce-detailed-info'),
    description: (0, _attr.default)('string'),
    fullOnline: Ember.computed('deliveryTypes', {
      get: function get() {
        var deliveryTypes = this.deliveryTypes;
        return deliveryTypes && deliveryTypes.length === 1 && deliveryTypes.indexOf('FULL_ONLINE') > -1;
      }
    }),
    gigabytes: (0, _attr.default)('string'),
    gigabytesUnlimited: Ember.computed('gigabytes', {
      get: function get() {
        return null == this.gigabytes;
      }
    }),
    gigabytesVisible: Ember.computed('gigabytes', {
      get: function get() {
        return '0' !== this.gigabytes;
      }
    }),
    hasBanner: Ember.computed('bannerDesktop', 'bannerMobile', {
      get: function get() {
        return false; //this.bannerDesktop || this.bannerMobile;
      }
    }),
    includes: (0, _attr.default)(''),
    isValid: Ember.computed('validity', {
      get: function get() {
        var _ref = this.validity || {},
            lower = _ref.lower,
            upper = _ref.upper;

        var now = new Date();

        if (lower && lower >= now) {
          return false;
        }

        if (upper && upper <= now) {
          return false;
        }

        return true;
      }
    }),
    showIncludes: Ember.computed('validity', 'includes', {
      get: function get() {
        return this.includes;
      }
    }),
    longDescription: (0, _attr.default)('string'),
    minutes: (0, _attr.default)('string'),
    minutesUnlimited: Ember.computed('minutes', {
      get: function get() {
        return null == this.minutes;
      }
    }),
    minutesVisible: Ember.computed('minutes', {
      get: function get() {
        return '0' !== this.minutes;
      }
    }),
    messages: (0, _attr.default)('string'),
    messagesUnlimited: Ember.computed('messages', {
      get: function get() {
        return null == this.messages;
      }
    }),
    messagesVisible: Ember.computed('messages', {
      get: function get() {
        return '0' !== this.messages;
      }
    }),
    // mnpOperators: hasMany('ecommerce-mnp-operator'),
    mnpList: (0, _attr.default)(''),
    name: (0, _attr.default)('string'),
    productId: (0, _attr.default)('string'),
    //partner: belongsTo('ecommerce-partner'),
    partnerList: (0, _relationships.hasMany)('ecommerce-partner'),
    pluses: (0, _relationships.hasMany)('ecommerce-plus'),
    plusesOnline: (0, _relationships.hasMany)('ecommerce-plus-online'),
    renewPrice: (0, _attr.default)('string'),
    renewPriceCurrency: (0, _attr.default)('string'),
    renewLaterPrice: (0, _attr.default)('string'),
    renewLaterIntervalAmount: (0, _attr.default)('string'),
    simPrice: (0, _attr.default)('string'),
    simPriceCurrency: (0, _attr.default)('string'),
    slug: Ember.computed('name', {
      get: function get() {
        return this.name.dasherize();
      }
    }),
    validity: (0, _attributes.fragment)('validity')
  });

  _exports.default = _default;
});