define("teleseller/login-map", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var USERS = [{
    "isDisabled": false,
    "username": "ec_pcmlsr01",
    "password": "UHRuM1Rsc0A=" // "plain": "Ptn3Tls@"

  }, {
    "isDisabled": false,
    "username": "ec_abrav",
    "password": "UHRuV2luZDRA" // "plain": "PtnWind4@"

  }, {
    "isDisabled": false,
    "username": "ec_cosvi",
    "password": "UHRuNVRsc0A=" // "plain": "Ptn5Tls@"

  }, {
    "isDisabled": false,
    "username": "ec_pcgdlr01",
    "password": "UHRuV2luZDZA" // "plain": "PtnWind6@"

  }, {
    "isDisabled": false,
    "username": "ec_pcempw01",
    "password": "UHRuN1Rsc0A=" // "plain": "Ptn7Tls@"

  }, {
    "isDisabled": false,
    "username": "ec_pcfnxn01",
    "password": "UHRuV2luZDhA" // "plain": "PtnWind8@"

  }, {
    "isDisabled": true,
    "username": "ec_pcflpc01",
    "password": "UHRuOVRsc0A=" // "plain": "Ptn9Tls@"

  }, {
    "isDisabled": false,
    "username": "ec_pcfxsr01",
    "password": "UHRuV2luZDEwQA==" // "plain": "PtnWind10@"

  }, {
    "isDisabled": false,
    "username": "ec_pmfxsr01",
    "password": "UHRuMTFUbHNA" // "plain": "Ptn11Tls@"

  }, {
    "isDisabled": false,
    "username": "ec_pcgrnc04",
    "password": "UHRuV2luZDEyQA==" // "plain": "PtnWind12@"

  }, {
    "isDisabled": false,
    "username": "ec_pcints01",
    "password": "UHRuMTNUbHNA" // "plain": "Ptn13Tls@"

  }, {
    "isDisabled": false,
    "username": "ec_pcmdcm01",
    "password": "UHRuV2luZDE0QA==" // "plain": "PtnWind14@"

  }, {
    "isDisabled": true,
    "username": "ec_pcmdtc02",
    "password": "UHRuMTVUbHNA" // "plain": "Ptn15Tls@"

  }, {
    "isDisabled": true,
    "username": "ec_pcplnt01",
    "password": "UHRuV2luZDE2QA==" // "plain": "PtnWind16@"

  }, {
    "isDisabled": false,
    "username": "ec_pcplcg01",
    "password": "UHRuMTdUbHNA" // "plain": "Ptn17Tls@"

  }, {
    "isDisabled": false,
    "username": "ec_pcprfs01",
    "password": "UHRuV2luZDE4QA==" // "plain": "PtnWind18@"

  }, {
    "isDisabled": false,
    "username": "ec_pcrfsr01",
    "password": "UHRuMTlUbHNA" // "plain": "Ptn19Tls@"

  }, {
    "isDisabled": false,
    "username": "ec_pctrpr01",
    "password": "UHRuV2luZDIwQA==" // "plain": "PtnWind20@"

  }, {
    "isDisabled": false,
    "username": "ec_cosvi_155_1",
    "vendorCode": "cosvi_155_1",
    "password": "UHRuMjFUbHNA" // "plain": "Ptn21Tls@",

  }, {
    "isDisabled": false,
    "username": "ec_cosvi_155_2",
    "vendorCode": "cosvi_155_2",
    "password": "UHRuV2luZDIyQA==" // "plain": "PtnWind22@",

  }, {
    "isDisabled": false,
    "username": "ec_comci_155_1",
    "vendorCode": "comci_155_1",
    "password": "UHRuMjNUbHNA" // "plain": "Ptn23Tls@",

  }, {
    "isDisabled": false,
    "username": "ec_comci_155_2",
    "password": "UHRuV2luZDI0QA==" // "plain": "PtnWind24@",

  }, {
    "isDisabled": false,
    "username": "ec_abrav_155_1",
    "password": "UHRuMjVUbHNA" // "plain": "Ptn25Tls@",

  }, {
    "isDisabled": false,
    "username": "ec_comci_155_3",
    "password": "UHRuV2luZDI2QA==" // "plain": "PtnWind26@",

  }, {
    "isDisabled": false,
    "username": "ec_comci_155_4",
    "password": "UHRuMjdUbHNA" // "plain": "Ptn27Tls@",

  }, {
    "isDisabled": false,
    "username": "ec_sinci_155_1",
    "password": "UHRuV2luZDI4QA==" // "plain": "PtnWind28@",

  }, {
    "isDisabled": false,
    "username": "ec_ntwcl_155_1",
    "password": "UHRuMjlUbHNA" // "plain": "Ptn29Tls@",

  }, {
    "isDisabled": false,
    "username": "ec_cosvi_155_3",
    "vendorCode": "cosvi_155_3",
    "password": "UHRuV2luZDMwQA==" // "plain": "PtnWind30@",

  }, {
    "isDisabled": false,
    "username": "ec_cosvi_155_4",
    "password": "UHRuMzFUbHNA" // "plain": "Ptn31Tls@",

  }, {
    "isDisabled": false,
    "username": "ec_cosvi_155_5",
    "password": "UHRuV2luZDMyQA==" // "plain": "PtnWind32@",

  }, {
    "isDisabled": false,
    "username": "ec_cosvi_155_6",
    "password": "UHRuMzNUbHNA" // "plain": "Ptn33Tls@",

  }, {
    "isDisabled": false,
    "username": "ec_cosvi_155_7",
    "password": "UHRuV2luZDM0QA==" // "plain": "PtnWind34@",

  }, {
    "isDisabled": false,
    "username": "ec_ntwc_155_1",
    "vendorCode": "ntwc_155_1",
    "password": "UHRuMzVUbHNA" // "plain": "Ptn35Tls@",

  }, {
    "isDisabled": false,
    "username": "ec_ntwc_155_2",
    "vendorCode": "ntwc_155_2",
    "password": "UHRuV2luZDM2QA==" // "plain": "PtnWind36@",

  }, {
    "isDisabled": false,
    "username": "ec_ntwc_155_3",
    "vendorCode": "ntwc_155_3",
    "password": "UHRuMzU3VGxzQA==" // "plain": "Ptn357Tls@",

  }, {
    "isDisabled": false,
    "username": "ec_pcicll05",
    "password": "UHRuSUNMVGxzQA==" // "plain": "PtnICLTls@",

  }, {
    "isDisabled": false,
    "username": "ec_pcpshs01",
    "password": "UHRuU0hTbHNA" // "plain": "PtnSHSls@",

  }, {
    "isDisabled": true,
    "username": "ec_pcupsl01",
    "password": "UHRuVVBTcmxzQA==" // "plain": "PtnUPSrls@",

  }, {
    "isDisabled": false,
    "username": "ec_pcocns01",
    "password": "UHRuT0NOcmxzQA==" // "plain": "PtnOCNrls@",

  }, {
    "isDisabled": false,
    "username": "ec_pccmdt01",
    "password": "UHRuQ01EcmxzQA==" // "plain": "PtnCMDrls@",

  }, {
    "isDisabled": false,
    "username": "ec_wbcldc03",
    "vendorCode": "wbcldc03",
    "password": "UHRuQ0xEQ2xzQA==" // "plain": "PtnCLDCls@",

  }, {
    "isDisabled": false,
    "username": "ec_wbfclt05",
    "vendorCode": "wbfclt05",
    "password": "UHRuSUNMVGxzQA==" // "plain": "PtnICLTls@",

  }, {
    "isDisabled": false,
    "username": "ec_pcflyc01",
    "vendorCode": "pcflyc01",
    "password": "UHRuRkxZbHNA" // "plain": "PtnFLYls@",

  }, {
    "isDisabled": false,
    "username": "ec_wbsstr03",
    "vendorCode": "wbsstr03",
    "password": "UHRuU1RSbHNA" // "plain": "PtnSTRls@",

  }, {
    "isDisabled": false,
    "username": "ec_pcbsns01",
    "vendorCode": "PCBSNS01",
    "password": "UHRuU05TbHNA" // "plain": "PtnSNSls@",

  }, {
    "isDisabled": false,
    "username": "ec_pcbsns01",
    "vendorCode": "PCBSNS01",
    "password": "UHRuU05TbHNA" // "plain": "PtnSNSls@",

  }, {
    "isDisabled": false,
    "username": "ec_pcslsp01",
    "vendorCode": "PCSLSP01",
    "password": "UHRuU1BGMDFA" // "plain": "PtnSPF01@",

  }];
  var _default = USERS;
  _exports.default = _default;
});