define("teleseller/templates/error", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "J5Cesrlw",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"wind-error wind-app__content--header--footer\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"wind-error__content\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"wind-error__title__main\"],[9],[0,\"\\n      \"],[1,[27,\"t\",[[27,\"concat\",[\"wind.error.\",[23,[\"error\",\"code\"]],\".message\"],null]],null],false],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "teleseller/templates/error.hbs"
    }
  });

  _exports.default = _default;
});