define("teleseller/constants/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var ERRORS = {
    NOT_FOUND: 'NOT_FOUND',
    NOT_VALID: 'NOT_VALID',
    NO_OFFERS: 'NO_OFFERS'
  };
  var STORAGE_TLS_AUTH = 'teleseller-auth-4';
  var STORACE_TLS_INTERNAL_PARTNER_ID = 'teleseller-partner-4';
  var NO_OPERATOR = 'default';
  var _default = {
    ERRORS: ERRORS,
    NO_OPERATOR: NO_OPERATOR,
    STORAGE_TLS_AUTH: STORAGE_TLS_AUTH,
    STORACE_TLS_INTERNAL_PARTNER_ID: STORACE_TLS_INTERNAL_PARTNER_ID
  };
  _exports.default = _default;
});