define("teleseller/templates/components/widi-select", ["exports", "@dwind/widi-uikit/templates/components/widi-select"], function (_exports, _widiSelect) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _widiSelect.default;
    }
  });
});