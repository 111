define("teleseller/controllers/application", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    windAuth: Ember.inject.service(),
    brand: 'wind',
    version: '0.6.1',
    routeName: Ember.computed('currentRouteName', {
      get: function get() {
        return this.currentRouteName.replace(/\./g, '-');
      }
    }),
    setHeaderTitle: function setHeaderTitle(title) {
      Ember.set(this, 'headerTitle', title);
    },
    actions: {
      logout: function logout() {
        this.windAuth.logout();
        this.setHeaderTitle('');
        this.transitionToRoute('login');
      }
    }
  });

  _exports.default = _default;
});