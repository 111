define("teleseller/controllers/login", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    windAuth: Ember.inject.service(),
    formInvalid: Ember.computed('userValue', 'pswValue', 'loading', {
      get: function get() {
        return this.loading || !(this.userValue && this.pswValue);
      }
    }),
    actions: {
      login: function login(username, password) {
        var _this = this;

        Ember.set(this, 'loginError', false);
        Ember.set(this, 'loading', true);
        this.windAuth.login2(this.store, username, password).then(function () {
          var res = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
          Ember.set(_this, 'loading', false);

          _this.transitionToRoute('list', res.internalPartnerId);
        }).catch(function (err) {
          Ember.set(_this, 'loading', false);
          Ember.set(_this, 'loginError', err.code);
        });
      }
    }
  });

  _exports.default = _default;
});