define('teleseller/config/environment', [], function() {
  if (typeof FastBoot !== 'undefined') {
return FastBoot.config('teleseller');
} else {

          var exports = {
            'default': {"modulePrefix":"teleseller","environment":"production","rootURL":"/","locationType":"auto","EmberENV":{"FEATURES":{},"EXTEND_PROTOTYPES":{"Date":false},"_APPLICATION_TEMPLATE_WRAPPER":false},"APP":{"name":"teleseller","version":"1.0.0+503e4dea"},"apiHost":"https://api.shop.windtre.it","onebrandPartnersURL":"https://shop.windtre.it/partners","checkoutURL":"https://buy.shop.windtre.it/landing/checkout","sentry":{"dsn":"https://680c92f14f74452bb49075145329ec2e@api.shop.windtre.it/__sentry/48","whitelistUrls":[{}]},"fastboot":{"hostWhitelist":["api.shop.windtre.it","buy.shop.windtre.it","partners.windtre.it"]},"ifa":{"enabled":true,"inline":false},"exportApplicationGlobal":false}
          };
          Object.defineProperty(exports, '__esModule', {value: true});
          return exports;
        
}
});
