define("teleseller/models/ecommerce-product-family", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships"], function (_exports, _model, _attr, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    brand: (0, _relationships.belongsTo)('ecommerce-brand'),
    partner: (0, _relationships.belongsTo)('ecommerce-partner'),
    products: (0, _relationships.hasMany)('ecommerce-product'),
    description: (0, _attr.default)('string'),
    familyId: (0, _attr.default)('string'),
    name: (0, _attr.default)('string')
  });

  _exports.default = _default;
});