define("teleseller/models/validity", ["exports", "ember-data-model-fragments/fragment", "ember-data/attr"], function (_exports, _fragment, _attr) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _fragment.default.extend({
    lower: (0, _attr.default)('date'),
    upper: (0, _attr.default)('date')
  });

  _exports.default = _default;
});